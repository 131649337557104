<template>
  <section>
    <h1 class="sr-only">Sponsored video</h1>
    <YouTube
      v-if="videoCode"
      :video-code="videoCode"
      autoplay
      class="fixed inset-0 w-full h-full"
      @end="navigateToGame"
    />
    <v-btn
      color="red"
      dark
      class="fixed"
      style="right: 8px;bottom: 64px;"
      @click="navigateToGame"
      >Skip</v-btn
    >
  </section>
</template>

<script>
import Vue from "vue"

import { GetterTypes } from "@/store"

import YouTube from "@/components/YouTube.vue"

export default Vue.extend({
  name: "GameLatecomer",
  components: {
    YouTube
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    /** @returns {(string | undefined)} */
    videoCode() {
      return this.$store.getters[GetterTypes.LATECOMER_VIDEO_CODE]
    }
  },
  created() {
    if (!this.videoCode) {
      this.navigateToGame()
    }
  },
  methods: {
    navigateToGame() {
      this.$router.replace({ name: "game", params: this.id })
    }
  }
})
</script>
