var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Sponsored video")]),
      _vm.videoCode
        ? _c("YouTube", {
            staticClass: "fixed inset-0 w-full h-full",
            attrs: { "video-code": _vm.videoCode, autoplay: "" },
            on: { end: _vm.navigateToGame }
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "fixed",
          staticStyle: { right: "8px", bottom: "64px" },
          attrs: { color: "red", dark: "" },
          on: { click: _vm.navigateToGame }
        },
        [_vm._v("Skip")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }